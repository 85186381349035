import Swiper from "swiper";
import { Mousewheel, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";

class RelatedSlider {
  constructor(el) {
    this.el = el;
    this.swiper = this.el.querySelector(".swiper");

    if (this.swiper) {
      new Swiper(this.swiper, {
        autoHeight: false,
        spaceBetween: 10,
        slidesPerView: 1.2,
        loop: false,
        grabCursor: false,
        direction: "horizontal",
        mousewheel: {
          enabled: true,
          forceToAxis: true,
        },
        breakpoints: {
          667: {
            slidesPerView: 2.2,
            spaceBetween: 10,
          },
        },
        navigation: {
          prevEl: this.el.querySelector(".swiper-arrows .swiper-arrows__prev"),
          nextEl: this.el.querySelector(".swiper-arrows .swiper-arrows__next"),
        },
        modules: [Navigation, Mousewheel],
      });
    }
  }
}

function initRelatedSlider() {
  const slider = document.querySelector(".js-related-cases");
  if (slider) {
    new RelatedSlider(slider);
  }
}

initRelatedSlider();