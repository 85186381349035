class TabsScroll {
  constructor(el) {
    this.el = el;
    this.buttons = Array.from(this.el.querySelectorAll('.tab'));
    this.contents = Array.from(this.el.querySelectorAll('.circular-slider__item'));
    this.images = Array.from(this.el.querySelectorAll('.circular-slider__image'));
    this.navEl = this.el.querySelector(".tabs");
    this.imagesContainer = this.el.querySelector('.circular-slider__images');
    this.activeIndex = null;
    this.isInitializing = true;

    // Crear Map para acceder rápidamente a elementos por índice
    this.elementsMap = this.createElementsMap();

    this.initObserver();
    this.observeContents();
    this.attachEventListeners();
    this.activateFirstItem();

    this.isInitializing = false;
    window.addEventListener('resize', this.onResize.bind(this));
  }

  createElementsMap() {
    const map = new Map();
    this.contents.forEach(content => {
      const index = content.getAttribute('data-index');
      map.set(index, {
        content,
        button: this.el.querySelector(`.tab[data-index="${index}"]`),
        image: this.el.querySelector(`.circular-slider__image[data-index="${index}"]`)
      });
    });
    return map;
  }

  initObserver() {
    this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
      threshold: 0,
      rootMargin: "-20px",
    });
  }

  onResize() {
    if (this.observer) {
      this.observer.disconnect();
      this.observeContents();
    }
  }

  observeContents() {
    this.contents.forEach(content => this.observer.observe(content));
  }

  handleIntersect(entries) {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        const index = entry.target.getAttribute('data-index');
        if (this.activeIndex !== index) {
          this.setActiveItem(index);
        }
      }
    }
  }

  activateFirstItem() {
    if (this.buttons.length > 0) {
      const firstIndex = this.buttons[0].getAttribute('data-index');
      this.setActiveItem(firstIndex);
    }
  }

  setActiveItem(index) {
    if (!this.elementsMap.has(index)) return;
    
    this.removeActiveItem();
    this.activeIndex = index;

    const { content, button, image } = this.elementsMap.get(index);
    
    button.classList.add('marked');
    button.setAttribute('aria-selected', 'true');
    content.classList.add('show');
    content.setAttribute("data-active", true);

    if (!this.isInitializing && window.innerWidth >= 768) {
      content.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    this.scrollToButton(button);

    this.images.forEach(img => img.classList.remove('active'));
    if (image) image.classList.add('active');

    if (this.imagesContainer) {
      this.imagesContainer.setAttribute("data-index", index);
    }
  }

  removeActiveItem() {
    if (this.activeIndex !== null && this.elementsMap.has(this.activeIndex)) {
      const { content, button, image } = this.elementsMap.get(this.activeIndex);

      content.classList.remove('show');
      content.setAttribute("data-active", false);
      image?.classList.remove('active');
      button.classList.remove('marked');
      button.setAttribute('aria-selected', 'false');
      
      this.activeIndex = null;
    }
  }

  scrollToButton(button) {
    button.scrollIntoView({ behavior: 'smooth', inline: 'center' });
  }

  attachEventListeners() {
    this.buttons.forEach(button => {
      button.addEventListener('click', () => {
        const index = button.getAttribute('data-index');
        if (index) {
          this.setActiveItem(index);
          if (window.innerWidth < 768) {
            this.elementsMap.get(index)?.content.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }
        }
      });
    });
  }
}

function initTabsScroll() {
  document.querySelectorAll('.circular-slider').forEach(el => new TabsScroll(el));
}

document.addEventListener("DOMContentLoaded", initTabsScroll);
