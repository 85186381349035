class ModalWindow {
  el;
  closeButton;
  overlay;

  constructor(modal) {
    this.el = modal;

    // Obtener el botón de cierre y el overlay (fondo oscuro)
    this.closeButton = this.el.querySelector(".close");
    this.overlay = this.el;

    this.bindListeners();
  }

  bindListeners() {
    // Escuchar clics en el botón de apertura relacionado
    const openButton = document.querySelector(`#open-${this.el.id}`);
    if (openButton) {
      openButton.addEventListener("click", () => this.openModal());
    }

    // Escuchar clics en el botón de cierre
    if (this.closeButton) {
      this.closeButton.addEventListener("click", () => this.closeModal());
    }

    // Escuchar clics fuera del contenido del modal para cerrarlo
    this.overlay.addEventListener("click", (e) => {
      if (e.target === this.overlay) {
        this.closeModal();
      }
    });

    // Escuchar la tecla "Escape" para cerrar el modal
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape" && this.el.style.display === "flex") {
        this.closeModal();
      }
    });
  }

  openModal() {
    this.el.style.display = "flex";
    document.body.style.overflow = "hidden"; // Evitar scroll en la página
  }

  closeModal() {
    this.el.style.display = "none";
    document.body.style.overflow = ""; // Restaurar scroll

    const video = this.el.querySelector('video');

    if(video) {
      video.pause();
    }
  }
}

// Inicializar todos los modales en la página
const modals = document.querySelectorAll(".modal");
if (modals) {
  modals.forEach(modal => {
    new ModalWindow(modal);
  });
}