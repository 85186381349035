class PaginationPressReleases {
  constructor(el) {
    this.el = el;

    this.bindListeners();
  }

  bindListeners() {
    this.el.addEventListener("click", this.handleClick.bind(this));
  }

  handleClick() {
    const hiddenItems = document.querySelectorAll(".press-releases__row.hide");

    Array.from(hiddenItems).slice(0, 5).forEach(item => {
      item.classList.add("show");
      item.classList.remove("hide");
    });

    const remainingHide = Array.from(hiddenItems).some(item => item.classList.contains("hide"));

    if (!remainingHide) {
      this.el.classList.add('hide');
    }
  }
}

function initPaginationPressReleases() {
  const showMorebtn = document.querySelector(".press-releases__pagination button");

  if (showMorebtn) {
    new PaginationPressReleases(showMorebtn);
  }
}

initPaginationPressReleases();