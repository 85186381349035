import Swiper from "swiper";
import { Mousewheel, Navigation, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";

class GallerySlider {
  constructor(el) {
    this.el = el;
    this.swiper = this.el.querySelector(".gallery-slider__slider.swiper");

    if (this.swiper) {
      new Swiper(this.swiper, {
        autoHeight: false,
        spaceBetween: 10,
        slidesPerView: 1.025,
        loop: false,
        grabCursor: false,
        // slidesOffsetBefore: 20,
        // slidesOffsetAfter: 20,
        direction: "horizontal",
        mousewheel: {
          enabled: true,
          forceToAxis: true,
        },

        breakpoints: {
          667: {
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 1.5,
          },
          992: {
            spaceBetween: 24,
            slidesPerView: 1.5,
          },
          1200: {
            slidesPerView: 1.35,
          },
          1800: {
            slidesPerView: 2.5,
          },

        },
        // pagination: {
        //   el: ".swiper-pagination",
        //   type: "fraction",
        // },
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
          formatFractionCurrent: function (number) {
              return ('0' + number).slice(-2);
          },
          formatFractionTotal: function (number) {
              return ('0' + number).slice(-2);
          },
          renderFraction: function (currentClass, totalClass) {
              return '<span class="' + currentClass + '"></span>' +
                     ' — ' +
                     '<span class="' + totalClass + '"></span>';
          }
      },
        navigation: {
          prevEl: this.el.querySelector(".swiper-arrows .swiper-arrows__prev"),
          nextEl: this.el.querySelector(".swiper-arrows .swiper-arrows__next"),
        },
        modules: [Navigation, Mousewheel, Pagination],
      });
    }
  }
}

/* class CustomCursorGallery {
  constructor(slider) {
    this.body = document.querySelector("body");
    this.el = slider;
    this.customCursor = this.el.querySelector(".custom-cursor__gallery");
    // this.buttonsItems = this.el.querySelectorAll(".gallery-slider-card__btn");
    this.cursorX = 0;
    this.cursorY = 0;
    this.animationFrameId = null;

    this.bindListeners();
    this.handleResize();
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  bindListeners() {
    this.el.addEventListener("mousemove", this.handleMouseMove.bind(this));
    this.el.addEventListener("mouseenter", this.handleMouseEnter.bind(this));
    this.el.addEventListener("mouseleave", this.handleMouseLeave.bind(this));
    // this.buttonsItems.forEach((item) => {
    //   item.addEventListener("mouseenter", this.handleButtonMouseEnter.bind(this));
    //   item.addEventListener("mouseleave", this.handleButtonMouseLeave.bind(this));
    // });
  }

  handleMouseMove(e) {
    if (this.isTouchDevice()) return;

    this.cursorX = e.clientX;
    this.cursorY = e.clientY;
    this.cursorPosition();
  }

  handleMouseEnter() {
    if (this.isTouchDevice()) return;

    this.customCursor.style.display = "block";
    this.customCursor.style.opacity = "1";
  }

  handleMouseLeave() {
    if (this.isTouchDevice()) return;

    this.customCursor.style.opacity = "0";
    cancelAnimationFrame(this.animationFrameId);
  }

  handleButtonMouseEnter() {
    if (this.isTouchDevice()) return;

    this.customCursor.style.opacity = "0";
    cancelAnimationFrame(this.animationFrameId);
  }

  handleButtonMouseLeave() {
    if (this.isTouchDevice()) return;

    this.customCursor.style.display = "block";
    this.customCursor.style.opacity = "1";
  }

  handleResize() {
    this.customCursor.style.display = "none";
    this.customCursor.style.opacity = "0";
    cancelAnimationFrame(this.animationFrameId);
  }

  isTouchDevice() {
    return window.ontouchstart !== undefined;
  }

  cursorPosition() {
    if (this.isTouchDevice()) return;

    const rect = this.el.getBoundingClientRect();
    const cursorWidth = this.customCursor.offsetWidth;
    const cursorHeight = this.customCursor.offsetHeight;

    const translateX = this.cursorX - rect.left - cursorWidth / 2;
    const translateY = this.cursorY - rect.top - cursorHeight / 2;

    this.customCursor.style.transform = `translate(${translateX}px, ${translateY}px)`;

    this.animationFrameId = requestAnimationFrame(this.cursorPosition.bind(this));
  }
} */

function initGallerySliders() {
  const sliders = document.querySelectorAll(".js-cards-slider-gallery");

  if (sliders.length > 0) {
    sliders.forEach((slider) => {
      new GallerySlider(slider);
      //new CustomCursorGallery(slider);
    });
  }

}

initGallerySliders();