document.addEventListener('DOMContentLoaded', function () {
  const fileInput = document.querySelector('#your-cv');
  const fileLabel = document.querySelector('.file-label');

  if(fileInput) {
    fileInput.addEventListener('change', function () {
      //console.log(this.files);
      const fileName = this.files[0]?.name || 'Attach CV*';
      fileLabel.textContent = fileName;
    });
  }
});