(function () {
  var doc = document.documentElement;
  var w = window;

  var prevScroll = w.scrollY || doc.scrollTop;
  var curScroll;
  var direction = 0;

  var header = document.getElementById("site-header");
  var subMenus = document.querySelectorAll(".sub-menu");
  var linksWithChildren = document.querySelectorAll("#site-header .menu-item-has-children a");

  var checkScroll = function () {
    curScroll = w.scrollY || doc.scrollTop;

    if (curScroll > prevScroll) {
      direction = 2; // Scrolling down
    } else if (curScroll < prevScroll) {
      direction = 1; // Scrolling up
    }

    toggleHeader(direction, curScroll);

    prevScroll = curScroll;
  };

  var toggleHeader = function (direction, curScroll) {
    if (direction === 1 && curScroll < 84) {
      var submenuOpened = document.querySelector('.sub-menu.open');
      var linkOpened = document.querySelector('.menu-item > a.open');

      header.classList.remove("show", "hide", "scroll");
      header.style.setProperty("--submenu-before-height", (0));
      
      if(header.classList.contains('open')) {
        header.classList.remove('open');
      }
      if(submenuOpened) {
        submenuOpened.classList.remove('open');
      }
      if(linkOpened) {
        linkOpened.classList.remove('open');
      }
    } else if (direction === 2 && curScroll > 84) {
      header.classList.remove("show");
      header.classList.remove("unfolded");
      header.classList.remove("open");
      header.classList.add("hide");
      header.classList.add("scroll");
      const tabs = document.querySelector(".circular-slider__anchors");
      if (tabs) {
        tabs.style.top = "0";
      }
      subMenus.forEach(submenu => {
        submenu.classList.remove('open');
      });
      linksWithChildren.forEach(link => {
        link.classList.remove('open');
      });
    } else if (direction === 1 && curScroll > 84) {
      header.classList.remove("hide");
      header.classList.add("show");
      const tabs = document.querySelector(".circular-slider__anchors");
      if (tabs) {
        tabs.style.top = "85px";
      }
    }
  };

  window.addEventListener("scroll", checkScroll);
})();

//SUBMENUS CONTROL
class SiteSubmenu {
  constructor(el) {
    this.el = el;
    this.body = document.querySelector('body');
    this.siteHeader = document.querySelector('#site-header');
    this.menuItems = this.siteHeader.querySelectorAll('#menu-primary > li');
    this.link = this.el.querySelector(':scope > a');
    this.submenu = this.el.querySelector('#site-header .sub-menu');
    if(this.submenu) {
      this.submenuItems = this.submenu.querySelectorAll('li');
    }

    this.bindListeners();
  }

  bindListeners() {
    this.link.addEventListener("click", this.toggleSubmenu.bind(this));
    if(this.submenuItems) {
      this.submenuItems.forEach(item => {
        item.addEventListener('mouseover', this.controlSubmenuHoversIn.bind(this));
        item.addEventListener('mouseout', this.controlSubmenuHoversOut.bind(this));
      });
    }
    if(this.menuItems) {
      this.menuItems.forEach(item => {
        item.addEventListener('mouseover', this.controlMenuHoversIn.bind(this));
        item.addEventListener('mouseout', this.controlMenuHoversOut.bind(this));
      });
    }
  }

  controlSubmenuHoversIn(event) {
    const hoveredItem = event.currentTarget;

    this.submenuItems.forEach(submenuItem => {
      if(hoveredItem === submenuItem) {
        submenuItem.classList.add('hovered');
      }else {
        submenuItem.classList.add('not-hovered');
      }
    });
  }

  controlSubmenuHoversOut(event) {

    this.submenuItems.forEach(submenuItem => {
      submenuItem.classList.remove('hovered');
      submenuItem.classList.remove('not-hovered');
    });
  }

  controlMenuHoversIn(event) {
    const hoveredItem = event.currentTarget;

    this.menuItems.forEach(submenuItem => {
      if(hoveredItem === submenuItem) {
        submenuItem.classList.add('hovered');
      }else {
        submenuItem.classList.add('not-hovered');
      }
    });
  }

  controlMenuHoversOut(event) {

    this.menuItems.forEach(submenuItem => {
      submenuItem.classList.remove('hovered');
      submenuItem.classList.remove('not-hovered');
    });
  }

  toggleSubmenu(event) {
    event.stopPropagation();

    document.querySelectorAll(".menu-item-has-children .open").forEach(openItem => {
      if (openItem !== this.link) {
        openItem.classList.remove("open");
        openItem.nextElementSibling?.classList.remove("open");
      }
    });

    if (this.link.classList.contains('open')) {
      this.link.classList.remove('open');
      this.submenu.classList.remove('open');
      this.siteHeader.classList.remove('open');

      if (this.body.classList.contains('home') && !this.siteHeader.classList.contains('scroll')) {
        this.siteHeader.classList.remove('unfolded');
      }
    } else {
      this.link.classList.add('open');
      this.submenu.classList.add('open');
      this.siteHeader.classList.add('open');

      const submenuHeight = this.submenu.offsetHeight;
      const siteheaderHeight = this.siteHeader.offsetHeight;

      this.siteHeader.style.setProperty("--submenu-before-height", (submenuHeight + siteheaderHeight + 40 + 'px'));

      if (this.body.classList.contains('home') && !this.siteHeader.classList.contains('scroll')) {
        this.siteHeader.classList.add('unfolded');
      }
    }
  }
}

function initSiteSubmenus() {
  const itemsWithChildren = document.querySelectorAll('#site-header #menu-primary .menu-item-has-children');

  if (itemsWithChildren) {
    itemsWithChildren.forEach(item => {
      new SiteSubmenu(item);
    });
  }
}

initSiteSubmenus();

/* document.addEventListener('DOMContentLoaded', () => {  
  const homeHero = document.querySelector('.home-hero');
  const siteHeader = document.querySelector('.site-header');
  if(homeHero) {
    const deviceHeight = window.innerHeight;
    homeHero.style.height = (deviceHeight - siteHeader.offsetHeight) + "px";


    if (window.scrollY > 80) {
      siteHeader.classList.remove('show');
      siteHeader.classList.add('hide');
    }
  }
}); */
