class AccordionItems {
  constructor(el) {
    this.el = el;
    this.elItems = this.el.querySelectorAll(".accordion__item");
    for (let item of this.elItems) {
      new AccordionItem(item, this);
    }
  }

  closeOtherItems(currentItem) {
    this.elItems.forEach((item) => {
      if (item !== currentItem && item.classList.contains("expanded")) {
        const button = item.querySelector(".accordion__item-button");
        const panel = item.querySelector(".accordion__item-panel");

        // Close the previously opened item
        button.setAttribute("aria-expanded", false);
        panel.style.transition = "height .25s ease-in-out, opacity .25s ease-in-out .2s";
        panel.style.height = "0px";
        panel.style.opacity = "0";
        item.classList.remove("expanded");
      }
    });
  }
}

class AccordionItem {
  constructor(el, accordion) {
    this.el = el;
    this.accordion = accordion;
    this.elButton = this.el.querySelector(".accordion__item-button");
    this.elPanel = this.el.querySelector(".accordion__item-panel");

    this.elButton.addEventListener(
      "click",
      function () {
        const accordionExpanded =
          this.elButton.getAttribute("aria-expanded") === "true";

        if (!accordionExpanded) {
          // Close other items in the same accordion
          this.accordion.closeOtherItems(this.el);

          // Open the current item
          this.elButton.setAttribute("aria-expanded", true);
          this.elPanel.style.height = this.elPanel.scrollHeight + "px";
          this.elPanel.style.transition = "height .1s ease-in-out, opacity .5s ease-in-out .2s, margin-top .25s ease-in-out";
          this.elPanel.style.opacity = "1";
          this.el.classList.add("expanded");
        } else {
          // Close the current item
          this.elButton.setAttribute("aria-expanded", false);
          this.elPanel.style.transition = "height .25s ease-in-out, opacity .25s ease-in-out .2s, margin-top .25s ease-in-out .1s";
          this.elPanel.style.height = "0px";
          this.elPanel.style.opacity = "0";
          this.el.classList.remove("expanded");
        }
      }.bind(this),
      false
    );
  }
}

const initAccordions = () => {
  const accordions = document.querySelectorAll(".accordion");

  if (accordions) {
    for (let accordion of accordions) {
      new AccordionItems(accordion);
    }
  }
};

initAccordions();
