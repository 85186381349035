class scrollDown {
  el;

  constructor(btnScrollDown) {
    this.el = btnScrollDown;

    this.bindListeners();
  }

  bindListeners() {
    this.el.addEventListener("click", this.scroll.bind(this));
  }

  scroll() {
    const windowHeight = window.innerHeight;
    window.scrollBy({
      top: windowHeight,
      behavior: "smooth",
    });
  }
}

const btnScrollDown = document.querySelector("#js-scroll-down");
if (btnScrollDown) {
  new scrollDown(btnScrollDown);
}
