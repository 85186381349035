class SideMenu {
  el;
  buttonOpen;
  buttonClose;
  backdrop;
  body;

  constructor(sideMenu) {
    this.el = sideMenu;
    this.buttonOpen = document.querySelector(".button-side-menu");
    this.buttonClose = this.el.querySelector(".button-close");
    this.backdrop = document.querySelector(".backdrop");
    this.body = document.querySelector("body");
    this.parentBurgerLinks = this.el.querySelectorAll('#menu-burger > li');
    this.childrenBurgerLinks = this.el.querySelectorAll('#menu-burger .sub-menu > li');
    this.bindListeners();
  }

  bindListeners() {
    this.buttonOpen.addEventListener("click", this.open.bind(this));
    this.buttonClose.addEventListener("click", this.close.bind(this));
    this.backdrop.addEventListener("click", this.close.bind(this));

    if(this.parentBurgerLinks) {
      this.parentBurgerLinks.forEach(item => {
        item.addEventListener('mouseover', this.controlParentBurgerHoversIn.bind(this));
        item.addEventListener('mouseout', this.controlParentBurgerHoversOut.bind(this));
      });
    }
    //console.log(this.childrenBurgerLinks)
    if(this.childrenBurgerLinks) {
      this.childrenBurgerLinks.forEach(item => {
        //console.log('t')
        item.addEventListener('mouseover', this.controlChildrenBurgerLinksHoversIn.bind(this));
        item.addEventListener('mouseout', this.controlChildrenBurgerLinksHoversOut.bind(this));
      });
    }
  }

  controlParentBurgerHoversIn(event) {
    const hoveredItem = event.currentTarget;

    this.parentBurgerLinks.forEach(item => {
      if(hoveredItem === item) {
        item.classList.add('hovered');
      }else {
        item.classList.add('not-hovered');
      }
    });
  }

  controlParentBurgerHoversOut(event) {
    this.parentBurgerLinks.forEach(item => {
      item.classList.remove('hovered');
      item.classList.remove('not-hovered');
    });
  }

  controlChildrenBurgerLinksHoversIn(event) {
    //console.log('check');
    const hoveredItem = event.currentTarget;

    this.childrenBurgerLinks.forEach(item => {
      if(hoveredItem === item) {
        item.classList.add('hovered');
      }else {
        item.classList.add('not-hovered');
      }
    });
  }

  controlChildrenBurgerLinksHoversOut(event) {
    this.childrenBurgerLinks.forEach(item => {
      item.classList.remove('hovered');
      item.classList.remove('not-hovered');
    });
  }

  open() {
    this.el.style.opacity = "1";
    this.el.classList.add("open");
    this.backdrop.classList.add("show");
    this.backdrop.style.top = `${window.scrollY}px`;
    this.body.classList.add("no-scroll");
    this.submenuOpened = document.querySelector('.sub-menu.open');
    this.linkOpened = document.querySelector('.menu-item > a.open');
    if(this.submenuOpened) {
      this.submenuOpened.classList.remove('open');
    }
    if(this.linkOpened) {
      this.linkOpened.classList.remove('open');
    }

    this.siteHeader = document.getElementById('site-header');
    if(this.siteHeader.classList.contains('open') || this.siteHeader.classList.contains('unfolded')) {
      this.siteHeader.style.setProperty("--submenu-before-height", (0 + 'px'));
      this.siteHeader.classList.remove('open');
      this.siteHeader.classList.remove('unfolded');
    }
  }

  close() {
    this.el.classList.remove("open");
    this.backdrop.classList.remove("show");
    this.body.classList.remove("no-scroll");
  }
}

const sideMenu = document.querySelector(".js-side-menu");
if (sideMenu) {
  sideMenu.style.opacity = "0";
  new SideMenu(sideMenu);
}

document.addEventListener("DOMContentLoaded", () => {
  const menuItems = document.querySelectorAll(".menu-item-has-children");

  menuItems.forEach((menuItem) => {
    const link = menuItem.querySelector("a");
    
    if (link) {
      link.addEventListener("click", (e) => {
        e.preventDefault();
        menuItem.classList.toggle("active");

        // Cerrar otros submenús
        menuItems.forEach((item) => {
          if (item !== menuItem) {
            item.classList.remove("active");
          }
        });
      });
    }
  });
});
