import Swiper from "swiper";
import { Mousewheel, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";

class MediaCoverageSlider {
  constructor(el) {
    this.el = el;
    this.swiper = this.el.querySelector(".swiper");

    if (this.swiper) {
      new Swiper(this.swiper, {
        autoHeight: false,
        spaceBetween: 10,
        slidesPerView: 1.2,
        loop: false,
        grabCursor: false,
        direction: "horizontal",
        mousewheel: {
          enabled: true,
          forceToAxis: true,
        },

        breakpoints: {
          667: {
            slidesPerView: 2.5,
          },
          768: {
            slidesPerView: 2.4,
            spaceBetween: 15,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1536: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        },
        modules: [Navigation, Mousewheel],
      });
    }
  }
}

/* class CustomCursor {
  constructor(slider) {
    this.body = document.querySelector("body");
    this.el = slider;
    this.customCursor = this.el.querySelector(".custom-cursor__media");
    this.buttonsItems = this.el.querySelectorAll(".media-coverage__link");
    this.cursorX = 0;
    this.cursorY = 0;
    this.animationFrameId = null;

    this.bindListeners();
    this.handleResize();
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  bindListeners() {
    this.el.addEventListener("mousemove", this.handleMouseMove.bind(this));
    this.el.addEventListener("mouseenter", this.handleMouseEnter.bind(this));
    this.el.addEventListener("mouseleave", this.handleMouseLeave.bind(this));
    this.buttonsItems.forEach((item) => {
      item.addEventListener("mouseenter", this.handleButtonMouseEnter.bind(this));
      item.addEventListener("mouseleave", this.handleButtonMouseLeave.bind(this));
    });
  }

  handleMouseMove(e) {
    if (this.isTouchDevice()) return;

    this.cursorX = e.clientX;
    this.cursorY = e.clientY;
    this.cursorPosition();
  }

  handleMouseEnter() {
    if (this.isTouchDevice()) return;

    this.customCursor.style.display = "block";
    this.customCursor.style.opacity = "1";
  }

  handleMouseLeave() {
    if (this.isTouchDevice()) return;

    this.customCursor.style.opacity = "0";
    cancelAnimationFrame(this.animationFrameId);
  }

  handleButtonMouseEnter() {
    if (this.isTouchDevice()) return;

    this.customCursor.style.opacity = "0";
    cancelAnimationFrame(this.animationFrameId);
  }

  handleButtonMouseLeave() {
    if (this.isTouchDevice()) return;

    this.customCursor.style.display = "block";
    this.customCursor.style.opacity = "1";
  }

  handleResize() {
    this.customCursor.style.display = "none";
    this.customCursor.style.opacity = "0";
    cancelAnimationFrame(this.animationFrameId);
  }

  isTouchDevice() {
    return window.ontouchstart !== undefined;
  }

  cursorPosition() {
    if (this.isTouchDevice()) return;

    const rect = this.el.getBoundingClientRect();
    const cursorWidth = this.customCursor.offsetWidth;
    const cursorHeight = this.customCursor.offsetHeight;

    const translateX = this.cursorX - rect.left - cursorWidth / 2;
    const translateY = this.cursorY - rect.top - cursorHeight / 2 - 40;

    this.customCursor.style.transform = `translate(${translateX}px, ${translateY}px)`;

    this.animationFrameId = requestAnimationFrame(this.cursorPosition.bind(this));
  }
} */

function initMediaCoverageSliders() {
  const slider = document.querySelector(".js-media-cards-slider");
  if (slider) {
    new MediaCoverageSlider(slider);
    //new CustomCursor(slider);
  }
}

initMediaCoverageSliders();