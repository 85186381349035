class Tabs {
  constructor(buttonsSelector, descriptionsSelector) {
    this.buttons = document.querySelectorAll(buttonsSelector);
    this.descriptions = document.querySelectorAll(descriptionsSelector);
    this.init();
  }

  init() {
    this.buttons.forEach(button => {
      button.addEventListener('click', (e) => this.handleButtonClick(e));
    });

    if (this.buttons.length > 0) {
      this.buttons[0].click();
    }
  }

  handleButtonClick(event) {
    const targetDescription = event.target.getAttribute('data-control');

    this.descriptions.forEach(description => {
      description.classList.add('hide');
    });

    const activeDescription = document.querySelector(`.tabs__tab[data-desc="${targetDescription}"]`);
    if (activeDescription) {
      activeDescription.classList.remove('hide');
    }

    this.buttons.forEach(button => {
      button.setAttribute('aria-pressed', 'false');
    });

    event.target.setAttribute('aria-pressed', 'true');
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new Tabs ('.tabs__control', '.tabs__tab');
});

