const speed = 10;

class CardCounterWrapper {
    constructor(el) {
        this.el = el;
        this.cardCounters = this.el.querySelectorAll(".digit[data-number]");
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    observer.unobserve(entry.target);
                    const counter = entry.target;
                    const dataValue = counter.getAttribute('data-number');
                    const match = dataValue.match(/([^\d]*)(\d+)([^\d]*)/);
                    
                    if (match) {
                        const prefix = match[1] || '';
                        const number = parseInt(match[2], 10);
                        const suffix = match[3] || '';
                        
                        let currentNumber = 0;
                        const intervalId = setInterval(() => {
                            currentNumber++;
                            counter.textContent = prefix + currentNumber + suffix;
                            if (currentNumber === number) {
                                clearInterval(intervalId);
                            }
                        }, speed);
                    }
                }
            });
        }, { once: true });

        this.cardCounters.forEach((counter) => {
            observer.observe(counter);
        });
    }
}

const init = function () {
    const cardCountersWrappers = document.querySelectorAll(".js-digits-section");

    cardCountersWrappers.forEach((card) => {
        new CardCounterWrapper(card);
    });
};

init();
