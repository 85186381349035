class TabsScroll {
    constructor(el) {
      this.el = el;
      this.buttons = this.el.querySelectorAll('.tab');
      this.window = window;
      this.lastScrollTop = window.scrollY;
      this.contents = this.el.querySelectorAll('.tabs-scroll__item');
      this.activeIndex = null;
      this.navEl = this.el.querySelector(".tabs");
  
      const threshold = 0.3;
      this.observer = new IntersectionObserver(this.handleIntersect.bind(this), {
        threshold: threshold
      });
  
      this.observeContents();
      this.attachEventListeners();
    }
  
    observeContents() {
      this.contents.forEach(content => this.observer.observe(content));
    }
  
    handleIntersect(entries) {
      entries.forEach(entry => {
        const index = entry.target.getAttribute('data-index');
        const correspondingButton = this.el.querySelector(`.tab[data-index="${index}"]`);
  
        if (entry.isIntersecting) {
          if (this.activeIndex !== index) {
            this.setActiveItem(index, correspondingButton);
          }
        }
      });
    }
  
    setActiveItem(index, correspondingButton) {
      this.removeActiveItem();
      this.activeIndex = index;
      correspondingButton.classList.add('marked');
      const activeContent = this.el.querySelector(`.tabs-scroll__item[data-index="${index}"]`);
      activeContent.classList.add('show');
      activeContent.setAttribute("data-active", true);
    }
  
    removeActiveItem() {
      if (this.activeIndex !== null) {
        const previousButton = this.el.querySelector(`.tab[data-index="${this.activeIndex}"]`);
        const previousContent = this.el.querySelector(`.tabs-scroll__item[data-index="${this.activeIndex}"]`);
        previousContent.classList.remove('show');
        previousContent.setAttribute("data-active", false);
        previousButton.classList.remove('marked');
        this.activeIndex = null;
      }
    }
  
    attachEventListeners() {
      this.buttons.forEach(button => {
        button.addEventListener('click', () => {
          const index = button.getAttribute('data-index');
          if (index !== null) {
            this.setActiveItem(index, button);
            const targetItem = this.el.querySelector(`.tabs-scroll__item[data-index="${index}"]`);
            if (targetItem) {
              targetItem.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
            }
          }
        });
      });

      this.scrollBehaivor();
    }

    scrollBehaivor() {
      this.window.addEventListener("scroll", () => {
      const currentScroll = window.scrollY;
    
      if (currentScroll < this.lastScrollTop) {
        const tooltipHeight = document.querySelector('.site-header-toolbar').offsetHeight;
        this.navEl.style.top = tooltipHeight + 50 + 'px';
      }else {
        this.navEl.style.top = 'var(--s10)';
      }
    
      this.lastScrollTop = currentScroll;
    });

    }
  }
  
  function initTabsScroll() {
    const tabElements = document.querySelectorAll('.tabs-scroll');
    tabElements.forEach(el => new TabsScroll(el));
  }
  
  document.addEventListener("DOMContentLoaded", initTabsScroll);
  