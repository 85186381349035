import Swiper from "swiper";
import { Mousewheel, Navigation, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";

class GalleryChartsSlider {
  constructor(el) {
    this.el = el;
    this.swiper = this.el.querySelector(".gallery-charts-slider__slider.swiper");

    if (this.swiper) {
      new Swiper(this.swiper, {
        autoHeight: false,
        spaceBetween: 10,
        slidesPerView: 'auto',
        loop: false,
        grabCursor: false,
        direction: "horizontal",
        mousewheel: {
          enabled: true,
          forceToAxis: true,
        },
        breakpoints: {
          992: {
            spaceBetween: 24,
          },
        },
        navigation: {
          prevEl: this.el.querySelector(".swiper-arrows .swiper-arrows__prev"),
          nextEl: this.el.querySelector(".swiper-arrows .swiper-arrows__next"),
        },
        modules: [Navigation, Mousewheel, Pagination],
      });
    }
  }
}

/* class CustomCursorGalleryCharts {
  constructor(slider) {
    this.body = document.querySelector("body");
    this.el = slider;
    this.customCursor = this.el.querySelector(".custom-cursor__gallery");
    this.cursorX = 0;
    this.cursorY = 0;
    this.animationFrameId = null;

    this.bindListeners();
    this.handleResize();
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  bindListeners() {
    this.el.addEventListener("mousemove", this.handleMouseMove.bind(this));
    this.el.addEventListener("mouseenter", this.handleMouseEnter.bind(this));
    this.el.addEventListener("mouseleave", this.handleMouseLeave.bind(this));
  }

  handleMouseMove(e) {
    if (this.isTouchDevice()) return;

    this.cursorX = e.clientX;
    this.cursorY = e.clientY;
    this.cursorPosition();
  }

  handleMouseEnter() {
    if (this.isTouchDevice()) return;

    this.customCursor.style.display = "block";
    this.customCursor.style.opacity = "1";
  }

  handleMouseLeave() {
    if (this.isTouchDevice()) return;

    this.customCursor.style.opacity = "0";
    cancelAnimationFrame(this.animationFrameId);
  }

  handleButtonMouseEnter() {
    if (this.isTouchDevice()) return;

    this.customCursor.style.opacity = "0";
    cancelAnimationFrame(this.animationFrameId);
  }

  handleButtonMouseLeave() {
    if (this.isTouchDevice()) return;

    this.customCursor.style.display = "block";
    this.customCursor.style.opacity = "1";
  }

  handleResize() {
    this.customCursor.style.display = "none";
    this.customCursor.style.opacity = "0";
    cancelAnimationFrame(this.animationFrameId);
  }

  isTouchDevice() {
    return window.ontouchstart !== undefined;
  }

  cursorPosition() {
    if (this.isTouchDevice()) return;

    const rect = this.el.getBoundingClientRect();
    const cursorWidth = this.customCursor.offsetWidth;
    const cursorHeight = this.customCursor.offsetHeight;

    const translateX = this.cursorX - rect.left - cursorWidth / 2;
    const translateY = this.cursorY - rect.top - cursorHeight / 2;

    this.customCursor.style.transform = `translate(${translateX}px, ${translateY}px)`;

    this.animationFrameId = requestAnimationFrame(this.cursorPosition.bind(this));
  }
} */

function initGalleryChartsSliders() {
  const sliders = document.querySelectorAll(".js-cards-gallery-charts-slider");

  if (sliders.length > 0) {
    sliders.forEach((slider) => {
      new GalleryChartsSlider(slider);
      //new CustomCursorGalleryCharts(slider);
    });
  }

}

initGalleryChartsSliders();