class CaseFilter {
  constructor(filterBtn, allFilterBtns) {
    this.el = filterBtn;
    this.allFilterBtns = allFilterBtns;
    this.caseCards = document.querySelectorAll('.case-studies__item');

    this.bindListeners();
  }

  bindListeners() {
    this.el.addEventListener("click", () => this.filter());
  }

  filter() {
    this.allFilterBtns.forEach(btn => btn.classList.remove("selected"));
    this.el.classList.add("selected");

    const filterSolution = this.el.dataset.filter;

    let firstMatchFound = false;

    this.caseCards.forEach(caseCard => {
      const dataSolutions = caseCard.dataset.solutions || "";
      const isMatch = filterSolution === 'all' || dataSolutions.includes(filterSolution);
    
      caseCard.classList.toggle('filtered', isMatch);
    
      if (isMatch && !firstMatchFound) {
        caseCard.classList.add('full');
        firstMatchFound = true;
      } else {
        caseCard.classList.remove('full');
      }
    });
  }
}

const init = () => {
  const filterBtns = document.querySelectorAll(".case-studies__filter button");

  filterBtns.forEach(filterBtn => {
    new CaseFilter(filterBtn, filterBtns);
  });
};

init();